import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import htmlToPdf from '@/until/htmlToPdf'
Vue.use(htmlToPdf)
// 引入字体样式
// import './common/font/font.css';
Vue.config.productionTip = false
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import VueBus from 'vue-bus'
Vue.use(VueBus)
let vueMain = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vueMain