<template>
  <div class="app">
    <div v-if="navS != 0">
      <div class="title">
        <img src="@/assets/top-img.png" class="top-img" />
        <img src="@/assets/logo.png" class="absolute logo" />
        <img src="@/assets/tit1.png" class="absolute tit1" />
        <img
          src="@/assets/tit2.png"
          class="absolute tit2 pointer"
          @click="toUrl('/index', 1)"
        />
        <!-- <div class="absolute search-input flex between">
          <input placeholder="请输入搜索关键字" />
          <div class="sear-icon"><i class="el-icon-search"></i></div>
        </div> -->
        <!-- <div class="app-phone absolute">考生服务热线 | 88287080</div> -->
      </div>
      <div class="nav flex">
        <div
          v-for="(item, index) in navList"
          :key="index"
          class="nav-item"
          :class="navS == item.id ? 'nav-item-a' : ''"
          @click="toUrl(item.path, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <!-- 回顶部 -->
    <div class="go-top" v-if="btnFlag" @click="backTop">
      <img :src="imgGoTopSrc" />
      <br />
      <span>回顶部</span>
    </div>

    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      navS: 1,
      navList: [
        {
          id: 1,
          title: "首页",
          path: "/index",
        },
        {
          id: 2,
          title: "报考指南",
          path: "/finger",
        },
        {
          id: 3,
          title: "公示公告",
          path: "/notice",
        },
        {
          id: 4,
          title: "考试报名",
          path: "/exam",
        },
        {
          id: 5,
          title: "成绩查询",
          path: "/score",
        },
        {
          id: 6,
          title: "打印准考证",
          path: "/print",
        },
        {
          id: 7,
          title: "疑难解答",
          path: "/problem",
        },
        {
          id: 8,
          title: "联系我们",
          path: "/contact",
        },
      ],
      btnFlag: false,
      //记录屏幕滑动的值
      scrollTop: 0,
      imgGoTopSrc: require("@/assets/top.png"),
    };
  },
  mounted() {
    let that = this;
    this.$bus.on("navS", (value) => {
      that.navS = value;
    });
    window.addEventListener("scroll", that.scrollToTop, true);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    toUrl(u, n) {
      // if (this.navS != n) {
      this.$router.push(u);
      this.navS = n;
      // }
    },
    //返回顶部
    backTop() {
      const that = this;
      //使用定时器设置滑动过渡效果
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.go-top {
  position: fixed;
  z-index: 99;
  right: 13px;
  bottom: 50px;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 50px;
  cursor: pointer;
  // box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  img {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 12px;
  }
}
.input-item {
  input {
    font-size: 16px;
  }
  .el-input {
    width: 70%;
  }
  .el-select {
    width: 70%;
    .el-input {
      width: 100%;
    }
  }
  .el-radio-group {
    width: 70%;
    text-align: initial;
  }
  .el-cascader {
    width: 70%;
    .el-input {
      width: 100%;
    }
  }
  .el-textarea {
    width: 70%;
  }
}
body,
html {
  width: 100%;
  // height: 100%;
}
// ul li {
//   list-style: none;
//   font-size: 28px;
//   line-height: 55px;
// }
ul,
li {
  margin: 0;
  padding: 0;
}
.app {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}

.flex {
  display: flex;
}
.center {
  align-items: center;
}
.between {
  justify-content: space-between;
}
.absolute {
  position: absolute;
}
.title {
  width: 100%;
  position: relative;
  .top-img {
    width: 100%;
    display: block;
  }
  .logo {
    width: 38px;
    left: 17%;
    top: 20%;
  }
  .tit1 {
    width: 154px;
    top: 20%;
    left: 21%;
  }
  .tit2 {
    // width: 556px;
    // left: 325px;
    // top: 113px;
    width: 29.5%;
    top: 49%;
    left: 17%;
  }
  .search-input {
    width: 255px;
    top: 45%;
    right: 373px;
    background: white;
    padding: 8px 20px;
    border: solid 1px #b5ebff;
    input {
      border: none;
    }
    .sear-icon {
      color: #0089e1;
      border-left: solid 1px gainsboro;
      padding-left: 20px;
    }
  }
  .app-phone {
    right: 373px;
    top: 80%;
    font-size: 14px;
  }
}
.nav {
  background: #0089e1;
  height: 56px;
  line-height: 56px;
  justify-content: center;
  .nav-item {
    width: 160px;
    text-align: center;
    font-size: 18px;
    color: white;
    cursor: pointer;
  }
  .nav-item-a {
    background: #60bfff;
    font-weight: bold;
  }
}
.main {
  width: 67%;
  margin: 0 auto;
}
.el-carousel__indicators--outside button {
  background-color: #0089e1 !important;
}
.el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.border-bg {
  border-top: solid 3px #54c2ff;
  border-left: solid 1px #e6e6e6;
  border-right: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  padding-bottom: 10px;
  .border-tit {
    background: #f1f1f1;
    font-size: 12px;
    padding: 12px 19px;
  }
  .border-tit2 {
    text-align: center;
    font-size: 18px;
    line-height: 3;
  }
  .border-tit-info {
    width: 95%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding-bottom: 15px;
    border-bottom: solid 1px gainsboro;
    span {
      color: #1176bb;
    }
  }
  .border-tit-info div:nth-child(1) {
    margin-right: 30px;
  }
}
.pointer {
  cursor: pointer;
}
.zan {
  font-size: 16px;
  text-align: center;
  line-height: 3;
  color: gray;
}
// .el-carousel__container{
//   height: 224px;
// }
.content {
  width: 90%;
  margin: 20px auto;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
input:focus {
  outline: none;
}
.el-checkbox__inner::after {
  border-left: 0;
  border-top: 0;
}
.notice .content td {
  border: solid 1px black;
}
// 修改选择框颜色
.el-checkbox {
  color: #111;
  margin-right: 30px;
  font-size: 24px;
  margin-bottom: 18px;
}
// 修改选择框大小
.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.el-checkbox__label {
  font-size: 20px !important;
}
// 修改选择label字体颜色
.el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 20px !important;
}
// 修改中心对号大小以及位置
.el-checkbox__inner::after {
  left: 6px;
  height: 9px;
  width: 6px;
  top: 2px;
}
 .rig-con {
      font-size: 20px;
      span{
      font-size: 20px !important;
      }
    }
</style>
