import Vue from 'vue'
import VueRouter from 'vue-router'
// import Numbers from '../views/number.vue'
// import Index from '../views/index.vue'
// import Window from '../views/window.vue'
// import Login from '../views/login.vue'
// import LoginC from '../views/loginChuang.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import("@/views/index")
  },
  {
    path: '/finger',
    name: 'Finger',
    component: () => import("@/views/finger")
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import("@/views/notice")
  },
  {
    path: '/noticeInfo',
    name: 'NoticeInfo',
    component: () => import("@/views/noticeInfo")
  },
  {
    path: '/exam',
    name: 'Exam',
    component: () => import("@/views/exam")//考试报名
  },
  {
    path: '/score',
    name: 'Score',
    component: () => import("@/views/score")//成绩查询
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import("@/views/print")//打印准考证
  },
  {
    path: '/problem',
    name: 'Problem',
    component: () => import("@/views/problem")//疑难解答
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import("@/views/contact")//打印准考证
  },
  {
    path: '/scoreLogin',
    name: 'ScoreLogin',
    component: () => import("@/views/scoreLogin")//成绩查询
  },
  {
    path: '/scoreResult',
    name: 'ScoreResult',
    component: () => import("@/views/scoreResult")//成绩查询结果
  },
  {
    path: '/signLogin',
    name: 'SignLogin',
    component: () => import("@/views/signLogin")//报名登陆
  },
  {
    path: '/signReset',
    name: 'SignReset',
    component: () => import("@/views/signReset")//报名登陆
  },
  {
    path: '/signResign',
    name: 'SignResign',
    component: () => import("@/views/signResign")//报名注册
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import("@/views/userInfo")//报名注册
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import("@/views/apply")//报名注册
  },
  {
    path: '/printInfo',
    name: 'PrintInfo',
    component: () => import("@/views/printInfo")//打印
  },
  {
    path: '/printList',
    name: 'PrintList',
    component: () => import("@/views/printList")//准考证列表
  },
]

const router = new VueRouter({
  hash: 'history',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
